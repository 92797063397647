import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"

class ThankYou extends Component {
    render() {
        return (
            <Layout>
                <div className="container">
                    <h1>Thanks</h1>
                    <p>
                        Thank you for reaching out. We'll be in touch soon.
                    </p>
                </div>
            </Layout>
        )
    }
}

ThankYou.propTypes = {}

export default ThankYou
